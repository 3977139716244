<template>
   <transition name="slide">
    <div class="selection-modal__wrapper" v-if="isShowSelection">
         <div class="selection-modal__header">
             <div @click="handleClose()">
                  <img src="../../../static/images/icons/icon_arrow_left.png">
             </div>
             <div>{{$t(title)}}</div>
             <div></div>
             <!-- <div class="selection-modal__header-tick selected" @click="handleFinishSelect()"> </div> -->
         </div>
         <div class="selection-modal__body">
             <div class="selection-modal__body-list__wrapper">
                 <ul>
                     <li v-for="(o, index) in data" :key="index" class="selection-modal__body-list__item" @click="selectItem(o)">
                         <div class="display-flex-center">
                            <template v-if="title === 'SELECT_COUNTRY'">
                                <img :src="require(`../../../static/images/flags/${o.icon}.png`)" class="selection-modal__body-list__item-icon">
                                <span>{{o.name}}</span>
                            </template>
                            <template v-else>
                                <span>{{o.displayName}}</span>
                            </template>
                         </div>
                        <div v-if="o.selected">
                            <img src="../../../static/images/icons/icon_tick_green_selected.png">
                        </div>
                     </li>
                 </ul>
             </div>
         </div>
    </div>
   </transition>
</template>

<script>
export default {
     props: {
        data: {
            type: []
        },
        title: {
            type: String,
            default : ''
        },
    },
    data() {
        return {
            isShowSelection: false,
            selectedItem: {},
        }
    },
    methods: {
        handleOpen() {
            this.isShowSelection = true;
        },

        handleClose() {
            this.isShowSelection = false;
        },
        
        handleFinishSelect() {
            this.$emit('finishSelectEvent',this.selectedItem);
            this.handleClose();
        },

        selectItem(o) {
            this.data.forEach(x => {
                x.selected = false;
            });

            o.selected = true;
            this.selectedItem = o
            this.$emit('finishSelectEvent',this.selectedItem);
        }

    },
}
</script>

<style>
.selection-modal__wrapper{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--color-theme);
    z-index: 10;
    overflow-y: auto;
}

.selection-modal__header{
    color: white;
    display: flex;
    padding: 1rem;
    justify-content: space-between;
}

.selection-modal__header-tick{

}
.selection-modal__header-tick{
    width:1rem;
    height:1rem;
    background: url('../../../static/images/icons/icon_tick_unselect.png') center/cover no-repeat;
}
.selection-modal__header-tick.selected{
    background: url('../../../static/images/icons/icon_tick_selected.png') center/cover no-repeat;
}
.selection-modal__body-list__wrapper{
    padding: 0 1rem;
}
.selection-modal__body-list__item{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    font-size: 0.875rem;
    border-bottom: .1rem solid var(--color-grey-60);
}
.selection-modal__body-list__item-icon{
    width: 1.5rem;
    height: 1.5rem;
    object-fit: contain;
    margin-right: 1rem;
}
</style>