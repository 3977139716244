<template>
  <div class="container has-space">
    <template v-if="!isLogin">
      <div class="mobile-account__header mb-2rem">
        <div class="mobile-account__close" @click="handleClose()">
          <img src="../../../static/images/icons/icon_close.png" />
        </div>
        <div class="mobile-account__body-list__parent-name mt-15rem">
          {{ $t("PLEASE_LOGIN_OR_SIGN_UP") }}
        </div>
        <Setting></Setting>
      </div>

      <div class="mobile-account__body">
        <div class="mobile-account__body-list__container">
          <div class="mobile-account__body-list__parent-name">
            {{ $t("PERSONAL_SETTING") }}
          </div>
          <ul class="mobile-account__body-list__child">
            <li
              @click="handleLanguage"
              class="mobile-account__body-list__child-container"
            >
              <span>{{ $t("LANGUAGE") }}</span>
              <div>
                <span class="mobile-account__body-list__child-info">{{
                  userSelectedLangName
                }}</span
                ><!-- currentLanguageObj.displayName -->
                <img
                  src="../../../static/images/icons/icon_arrow_right_white.png"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </template>

    <template v-else-if="isLogin">
      <div class="mobile-account__header">
        <div class="mobile-account__close" @click="handleClose()">
          <img src="../../../static/images/icons/icon_close.png" />
        </div>
        <router-link :to="{ name: 'myProfile' }">
          <div class="display-flex-sb mb-3rem">
            <div class="display-flex-align-center hidden flex-1">
              <div class="mobile-account__user-icon__wrapper">
                <img
                  :src="`${s3ImgUrl}/${userInfo.profilePicture}`"
                  class="w-100 h-100"
                />
              </div>
              <div class="mobile-account-menu__wrapper">
                <div class="mobile-account-menu__user-name">
                  {{ userInfo.displayName }}
                </div>
                <div class="mobile-account-menu__user-join-date">
                  {{ $t("MEMBER_SINCE") }} {{ userRegistrationDate }}
                </div>
              </div>
            </div>
            <div>
              <img src="../../../static/images/icons/icon_pencil.png" />
            </div>
          </div>
        </router-link>
      </div>

      <div class="mobile-account__body">
        <div class="mobile-account__body-list__container">
          <div class="mobile-account__body-list__parent-name">
            {{ $t("PERSONAL_SETTING") }}
          </div>
          <ul class="mobile-account__body-list__child">
            <router-link
              :to="{ name: o.pathName }"
              v-show="o.active"
              v-for="(o, index) in menuList"
              :key="index"
              class="mobile-account__body-list__child-container"
            >
              <span>{{ $t(o.name) }}</span>
              <div>
                <span class="mobile-account__body-list__child-info">{{
                  o.value
                }}</span>
                <img
                  src="../../../static/images/icons/icon_arrow_right_white.png"
                />
              </div>
            </router-link>

            <li
              @click="handleCountry"
              class="mobile-account__body-list__child-container"
            >
              <span>{{ $t("COUNTRY") }}</span>
              <div>
                <span class="mobile-account__body-list__child-info">{{
                  currentCountryObj.name
                }}</span>
                <img
                  src="../../../static/images/icons/icon_arrow_right_white.png"
                />
              </div>
            </li>

            <li
              @click="handleLanguage"
              class="mobile-account__body-list__child-container"
            >
              <span>{{ $t("LANGUAGE") }}</span>
              <div>
                <span class="mobile-account__body-list__child-info">{{
                  userSelectedLangName
                }}</span
                ><!-- currentLanguageObj.displayName -->
                <img
                  src="../../../static/images/icons/icon_arrow_right_white.png"
                />
              </div>
            </li>
          </ul>
        </div>
        <div class="mobile-account__body-list__container">
          <div class="mobile-account__body-list__parent-name">
            {{ $t("LOG_OUT") }}
          </div>
          <ul class="mobile-account__body-list__child">
            <li @click="handleLogout()">{{ $t("LOG_OUT") }}</li>
          </ul>
        </div>
      </div>
    </template>
    <div class="mobile-account__footer">
      <APPDownloadNow></APPDownloadNow>
      <div class="footer-mobile__privacy">
        <router-link :to="{ name: 'privacypolicy' }">{{
          $t("PRIVACY_POLICY")
        }}</router-link>
      </div>
      {{ $t("COPYRIGHT") }} © {{ currentYear }} asiasports.com
    </div>
  </div>
  <Selection
    ref="countrySelectionRef"
    :title="'SELECT_COUNTRY'"
    :data="countryList"
    @finishSelectEvent="countrySelectEventChange($event)"
  ></Selection>

  <Selection
    ref="langSelectionRef"
    :title="'SELECT_LANGUAGE'"
    :data="userLangList"
    @finishSelectEvent="languageSelectEventChange($event)"
  ></Selection>
</template>

<script>
import Setting from "@/components/header/Setting.vue";
import Selection from "@/components/modal/Selection.vue";
import config from "@/js/config.js";
import APPDownloadNow from "@/components/mobile/app/APPDownloadNow.vue";

import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  components: {
    Setting,
    Selection,
    APPDownloadNow,
  },

  data() {
    return {
      s3ImgUrl: config.s3ImgUrl,
      currentYear: "",

      languageList: config.languageList,
      selectedLanguage: {
        displayName: "",
      },

      isShowPasswordSelect: true,

      menuList: [
        {
          id: 1,
          name: "PASSWORD",
          selected: false,
          pathName: "changePassword",
          active: true,
          value: "",
        },
        {
          id: 2,
          name: "MY_PHONE_NUMBER",
          selected: false,
          pathName: "myPhoneNumber",
          active: true,
          value: "",
        },
        {
          id: 4,
          name: "DELETE_ACCOUNT",
          selected: false,
          pathName: "deleteAccount",
          active: true,
          value: "",
        },
        {
          id: 3,
          name: "MY_REFERRAL_CODE",
          selected: false,
          pathName: "myReferralCode",
          active: true,
          value: "",
        },
      ],
      userSelectedLangName: null,

      userLangList: [],
      countryList: [],
      apiCallDone: true,

      fantasySportLogoutUrl: config.fantasySportLogoutUrl,
    };
  },
  computed: {
    ...mapGetters([
      "isMobile",
      "isLogin",
      "userInfo",
      "userRegistrationDate",
      "currentCountryObj",
      "currentCountryList",
      "currentLanguageObj",
      "currentLanguageList",
      "currentLocale",
      "userLangConfig",
      "userSelectedLangConfigObj",
    ]),
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapMutations([
      "USER_SWITCH_COUNTRY_LANG",
      "COUNTRY_CODE_TO_LANG_OBJ_LIST",
      "UPDATE_USER_LANG_CONFIG",
      "UPDATE_USER_SELECTED_LANG_CONFIG_OBJ",
      "REMOVE_USER_LANG_CONFIG",
      // "CURRENT_COUNTRY_CHANGE_EVENT",
    ]),
    ...mapActions([
      "currentLocaleChangeEvent",
      "postUpdateProfilePreferredLanguage",
      "getRetrieveUserInfo",
      "setUserData",
      "postLogout",
      "logoutEvent",
      "toastrChangeEvent",
      "commonVerifyLogin",
      "currentCountryChangeEvent",
      "postUpdateProfilePreferredCountry",
      "getCountryCodeByIp",
      "buildCountryBasedLangConfigUi",
      "currentCountryLocaleChangeEvent",
    ]),

    async init() {
      this.commonVerifyLogin();
      if (!this.isMobile && !this.isLogin) {
        //if in desktop version, redirect to my profile page
        if (!this.isMobile) {
          this.$router.push({ name: "myProfile" });
        }
        if (typeof this.userInfo !== "undefined") {
          this.menuList[2].value = this.userInfo.referralCode;
        }

        //get current year
        this.currentYear = new Date().getFullYear();

        this.getRetrieveUserInfoData();
        this.hideChangePassword();
      }

      if (this.isMobile) {
        if (this.isLogin) {
          this.initCountryList();
        }
        this.setLangList();
        this.$nextTick(() => {
          // also if user refreshed the page
          this.UPDATE_USER_SELECTED_LANG_CONFIG_OBJ();
          this.userSelectedLangName = this.currentLanguageObj.displayName; //this.userSelectedLangConfigObj.displayName;
        });
      }
    },

    initCountryList() {
      this.countryList = this.currentCountryList;
      this.countryList.forEach((x) => {
        x.selected =
          x.countryCode === this.currentCountryObj.countryCode ? true : false;
      });
    },

    setCountryList(o) {
      this.countryList = this.currentCountryList;
      this.countryList.forEach((x) => {
        x.selected = x.countryCode === o.countryCode ? true : false;
      });
    },

    async setLangList() {
      const user = localStorage.getItem("user");
      let countryCode;
      let supportedLocaleList;
      if (user === null) {
        // && !this.currentCountryObj
        let result = await this.getCountryCodeByIp();
        countryCode = result.result.countryCode;
        if (result.message === "Success") {
          this.currentCountryChangeEvent(countryCode);
        }
      }
      if (this.isLogin) {
        supportedLocaleList = this.currentCountryList.filter(
          (x) => x.countryCode === this.userInfo.preferredCountry
        )[0].supportedLocale;
      } else {
        supportedLocaleList = this.currentCountryList.filter(
          (x) => x.countryCode === countryCode
        )[0]?.supportedLocale;
      }
      this.userLangList = [];
      if (typeof supportedLocaleList === "undefined") {
        this.currentLanguageList.forEach((obj) => {
          if (obj.displayLocale === "en") {
            obj.selected = true;
            this.userLangList.push(obj);
          }
        });
      } else {
        supportedLocaleList.forEach((locale) => {
          this.currentLanguageList.forEach((obj) => {
            if (obj.displayLocale === locale) {
              obj.selected =
                obj.displayLocale === this.currentLocale ? true : false;
              this.userLangList.push(obj);
            }
          });
        });
      }
    },

    setLangListBaseOnCountryCode(countryCode) {
      if (this.isLogin) {
        this.userLangList = [];
        let supportedLocaleList = this.currentCountryList.filter(
          (x) => x.countryCode === countryCode
        )[0].supportedLocale;

        supportedLocaleList.forEach((locale) => {
          this.currentLanguageList.forEach((obj) => {
            if (obj.displayLocale === locale) {
              obj.selected =
                obj.displayLocale === this.currentLocale ? true : false;
              this.userLangList.push(obj);
            }
          });
        });
      }
    },

    //handle Close
    handleClose() {
      this.$router.push(`/${this.currentLocale}`);
    },

    // handle country
    handleCountry() {
      this.$refs.countrySelectionRef.handleOpen();
    },

    //handle language
    handleLanguage() {
      this.$refs.langSelectionRef.handleOpen();
    },

    //handle logout
    async handleLogout() {
      let params = {
        token: this.userInfo.token,
      };

      const result = await this.postLogout(params);
      this.logoutEvent();

      //logout fantasy
      let fantasyHtml = $(
        `<iframe class="fantasy-iframe-logout" onload="$('.fantasy-iframe-logout').remove()" src="${this.fantasySportLogoutUrl}?logout=true"></iframe>`
      );
      $("body").append(fantasyHtml);

      this.REMOVE_USER_LANG_CONFIG();
      if (this.$route.meta.parent === "account") {
        this.$router.push(`/${this.currentLocale}`);
      }
      // if (result.result.businessCode === 0) {

      // } else {
      //     this.$root.toastrMsg = result.result.message;
      //     this.$root.toastrType = 'info';
      //     this.$root.toastrPosition = "top";
      //     this.$root.$refs.toastrRef.handleOpen();
      // }
    },

    // get user info
    async getRetrieveUserInfoData() {
      let params = {
        token: this.userInfo.token,
        timeZone: this.$tools.getCurrentTimeZone(),
      };

      const result = await this.getRetrieveUserInfo(params);

      this.setUserData(result.result.data);
      this.menuList[2].value = this.userInfo.referralCode;
    },

    // after user select country
    async countrySelectEventChange(e) {
      if (this.isLogin && this.apiCallDone) {
        this.apiCallDone = false;
        // this.currentCountryChangeEvent(e.countryCode);
        this.setCountryList(e);
        this.setLangListBaseOnCountryCode(e.countryCode);
        this.userLangList.forEach((lang, i) => {
          lang.selected = i === 0 ? true : false;
        });
        let defaultCountryLangObj = this.userLangList[0];

        let params = {
          preferredCountry: e.countryCode,
        };

        const result = await this.postUpdateProfilePreferredCountry(params);
        this.apiCallDone = true;

        if (result.result.businessCode === 0) {
          // this.getRetrieveUserInfoData();
          let localStorageUser = JSON.parse(localStorage.getItem("user"));
          localStorageUser.preferredCountry = e.countryCode;
          this.setUserData(localStorageUser);
          this.languageSelectEventChange(defaultCountryLangObj, e.countryCode);
        } else {
          let params = {
            isOpen: true,
            msg: result.result.message,
            type: "info",
          };
          this.toastrChangeEvent(params);
        }
      }
    },

    //after user select language
    async languageSelectEventChange(e, countryCode = null) {
      if (this.apiCallDone) {
        this.apiCallDone = false;

        if (this.isLogin) {
          let theCountryCode =
            countryCode === null
              ? this.currentCountryObj.countryCode
              : countryCode;
          let params = {
            preferredLanguage: e.locale,
            token: this.userInfo.token,
          };

          const result = await this.postUpdateProfilePreferredLanguage(params);
          this.apiCallDone = true;

          // this.currentLocaleChangeEvent(e.displayLocale);
          // this.currentCountryChangeEvent(theCountryCode);

          if (result.result.businessCode === 0) {
            // this.getRetrieveUserInfoData();
            let localStorageUser = JSON.parse(localStorage.getItem("user"));
            localStorageUser.preferredLanguage = e.locale;
            this.setUserData(localStorageUser);

            this.currentCountryLocaleChangeEvent({
              countryCode: theCountryCode,
              locale: e.locale,
            });

            this.USER_SWITCH_COUNTRY_LANG();

            this.currentLocaleChangeEvent(e.displayLocale); //currentLanguageObj
          } else {
            let params = {
              isOpen: true,
              msg: result.result.message,
              type: "info",
            };
            this.toastrChangeEvent(params);

            // this.$root.toastrMsg = result.result.message;
            // this.$root.$refs.toastrRef.handleOpen();
          }

          //this.currentLanguageObj = e;
        } else {
          this.apiCallDone = true;
          this.currentLocaleChangeEvent(e.displayLocale);
        }

        this.USER_SWITCH_COUNTRY_LANG();
        this.UPDATE_USER_LANG_CONFIG(e.displayLocale);
        this.UPDATE_USER_SELECTED_LANG_CONFIG_OBJ();
        this.userSelectedLangName = this.currentLanguageObj.displayName;
      }
    },
    hideChangePassword() {
      if (this.userInfo.authProvider !== "LOCAL") {
        this.menuList.forEach((x) => {
          if (x.id === 1) {
            // change password
            x.active = false;
          }
        });
      }
    },
  },
};
</script>

<style>
.mobile-account-menu__wrapper {
  width: 70%;
}
.mobile-account-menu__user-join-date {
  color: #6f8c9b;
  font-size: 0.6875rem;
}
.mobile-account__user-icon__wrapper {
  width: 2.25rem;
  height: 2.25rem;
  cursor: pointer;
  position: relative;
  border-radius: 50%;
  border: 0.3rem solid var(--bg-selected);
  margin-right: 1.5rem;
  overflow: hidden;
}
.mobile-account__close {
  padding: 0 0 1.5rem 0.5rem;
}
.mobile-account__body-list__container {
  margin-bottom: 2.1875rem;
}
.mobile-account__body-list__parent-name {
  font-size: 0.6875rem;
  color: var(--color-grey-80);
  margin-bottom: 1rem;
}
.mobile-account__body-list__child-container {
  padding: 0.5rem 0;
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;
}
.mobile-account__body-list__child-info {
  color: var(--color-grey-80);
  margin-right: 1rem;
}
.mobile-account__footer {
  font-size: 0.6875rem;
  color: #5c7784;
  position: absolute;
  bottom: 1rem;
}
</style>
